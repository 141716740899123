import React from 'react';
import { Link } from 'gatsby';
import WayFadeUp from '../transitions/WayFadeUp';
import '../../css/project-card.css';

import { PrismicImage } from '../common/prismicUtils';

const ProjectCard = ({ project, image, ...other }) => {
  const { project_name, project_color, subheading } = project.data;
  const { uid, type } = project;

  const path =
    type === 'under_construction'
      ? `/under-construction/${uid}`
      : `/projects/${uid}`;

  return (
    <div {...other}>
      <div
        className={`relative center ${
          image?.fluid?.aspectRatio < 1 ? 'portrait' : 'landscape'
        }`}
      >
        <WayFadeUp>
          <Link to={path} className='link'>
            <PrismicImage {...image} />
            <div className='w-100 inline-flex-l items-center justify-center pt3 pt0-l proj-overlay'>
              <div
                className='absolute top-0 left-0 o-80 h-100 w-100 dn color-overlay'
                style={{ backgroundColor: `${project_color}` }}
              />
              <h4 className='f4 f3-l grey tiempos-reg z-10 relative mt0 mb0 tc'>
                {project_name.text} / {subheading.text}
              </h4>
            </div>
          </Link>
        </WayFadeUp>
      </div>
    </div>
  );
};

export default ProjectCard;

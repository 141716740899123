import React from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

// Import Components
import WayFadeUp from '../components/transitions/WayFadeUp';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VideoBanner from '../components/VideoBanner';
import HomepageProjectCard from '../components/HomepageProjectCard';

const IndexPage = ({ data: { prismicHomePage } }) => {
  const { data } = prismicHomePage;
  const {
    quote,
    text,
    text_two,
    one_up_project,
    large_project,
    small_project,
    is_reverse_alignment,
  } = data;

  return (
    <Layout headerColor='white'>
      <SEO title='Home' />
      <VideoBanner video={data.video} headline={data.headline} />
      <WayFadeUp>
        <div id='intro' className='mt4 mb4 mt6-l mb6-l pl4 pr4 tl tc-l'>
          <div className='f2 f1-l grey measure-narrow center lh-copy tiempos'>
            {quote.text}
          </div>

          <div className='center lh-copy f4 tl grey mt5-l mw8 flex-l flex-wrap'>
            <div
              className='w-50-l pr4-l'
              dangerouslySetInnerHTML={{ __html: text.html }}
            />
            <div
              className='w-50-l pl4-l'
              dangerouslySetInnerHTML={{ __html: text_two.html }}
            />
          </div>
        </div>
      </WayFadeUp>

      {one_up_project && (
        <div className='pl4 pr4 pl6-l pr6-l'>
          <HomepageProjectCard isLandscape project={one_up_project} />
        </div>
      )}

      <div
        className='w-100 flex flex-wrap pl4 pr4 pl6-l pr6-l'
        style={{
          display: 'flex',
          margin: '0 auto',
          flexDirection: is_reverse_alignment ? 'row-reverse' : 'row',
        }}
      >
        {large_project && (
          <div className='w-100 w-50-l mt6-l'>
            <HomepageProjectCard project={large_project} />
          </div>
        )}
        {small_project && (
          <div className='w-100 w-40-l ml-auto-l'>
            <HomepageProjectCard project={small_project} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicHomePage {
      ...HomeBody
    }
  }
`;

export default withPreview(IndexPage);

import React from 'react';

const ProjectCategoryButtons = ({ activeCategory, setActiveCategory }) => {
  return (
    <div className='pl4 pr4 pl5-l pr5-l'>
      <div
        className='project-category-buttons'
        style={{ columnGap: ['1.25rem', '1.25rem', '3.75rem'] }}
      >
        <span
          className={` ${
            activeCategory === 'projects-under-construction'
              ? 'gold'
              : 'category-grey'
          }`}
          onClick={() => setActiveCategory('projects-under-construction')}
          style={{ cursor: 'pointer' }}
        >
          Under Construction
        </span>
        <span
          className={` ${
            activeCategory === 'projects-complete' ? 'gold' : 'category-grey'
          }`}
          onClick={() => setActiveCategory('projects-complete')}
          style={{ cursor: 'pointer' }}
        >
        Completed Projects
        </span>
      </div>
    </div>
  );
};

export default ProjectCategoryButtons;

import React, { useState, useRef } from 'react';
import { useSpring, animated, useChain, config } from 'react-spring';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { PrismicImage } from '../common/prismicUtils';

const ProjectHeader = ({
  feature_image,
  project_name,
  project_intro,
  project_color,
  subheading,
  awards,
}) => {
  const boxStyle = {
    backgroundColor: `${project_color}`,
  };
  const imageRef = useRef();
  const { i } = useSpring({
    ref: imageRef,
    from: {
      i: '0',
    },
    to: {
      i: '1',
    },
    config: config.slow,
  });
  const innerRef = useRef();
  const { x, y } = useSpring({
    ref: innerRef,
    from: {
      x: '0',
      y: '20',
    },
    to: {
      x: '1',
      y: '0',
    },
    config: config.slow,
  });
  useChain([imageRef, innerRef]);
  return (
    <div
      className='w-100 flex-m flex-l vh-100-l overflow-hidden justify-between d-flex flex-column flex-row-l '
      style={boxStyle}
    >
      <animated.div
        className='w-100 w-100-m w-60-l  vh-50-m vh-100-l'
        style={{
          opacity: i.interpolate((i) => i),
        }}
      >
        <PrismicImage
          {...feature_image.fluid}
          className='obj-cover  w-100 vh-50-m  vh-100-l '
        />
      </animated.div>
      <div className='w-100 w-100-m w-40-xl white pa4 pb5 pl5-m pr5-m pl5-l pr5-l pt0-l pb0-l inline-flex-m inline-flex-l items-center project-header'>
        <animated.div
          className='mw6-l center mt6-l'
          style={{
            opacity: x.interpolate((x) => x),
            transform: y.interpolate((y) => `translate3d(0,${y}%, 0)`),
          }}
        >
          <h2 className='f3 mt0 mb0 lh-copy'>{subheading.text}</h2>
          <h1 className='f1 f-subheadline-l measure tiempos mt0 mb0 lh-solid mb4'>
            {project_name.text}
          </h1>
          <div
            className='f4 lh-copy o-80'
            dangerouslySetInnerHTML={{ __html: project_intro.html }}
          />
          {awards && (
            <div>
              {awards?.map(
                (award, index) =>
                  award.award.fluid && (
                    <div key={index} className='dib mr3'>
                      <PrismicImage {...award.award.fluid} className=' w3 h3' />
                    </div>
                  )
              )}
            </div>
          )}
          <div className='links flex-l mt4 mt5-l'>
            <AnchorLink
              href='#project-content'
              className='link white f3 mr4'
              offset='120'
            >
              Explore Project
            </AnchorLink>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default ProjectHeader;

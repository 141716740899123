import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntroText from '../components/IntroText';
import ImageText from './ImageText';
import AwardsTable from './AwardsTable';
import TwoColText from './TwoColText';
import ServiceSlider from './ServiceSlider';
import SingleIntroText from './SingleColumnIntroText';
import BeltDragger from '../components/BeltDragger';
import FeaturedImageText from './FeaturedImageText';

export default class PageSliceZone extends Component {
  render() {
    const { allSlices } = this.props;
    const slice = allSlices.map((s) => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'intro_text':
          return (
            <IntroText
              key={s.id}
              quote={s.primary.quote}
              text={s.primary.text}
            />
          );
        case 'single_column_intro_text':
          return (
            <SingleIntroText
              key={s.id}
              quote={s.primary.quote}
              text={s.primary.text}
            />
          );
        case 'image___text':
          return <ImageText key={s.id} input={s} />;
        case 'cc_image___text':
          return <ImageText key={s.id} input={s} />;
        case 'featured_image___text':
          return <FeaturedImageText key={s.id} input={s} />;
        case 'carousel_slider':
          return <BeltDragger key={s.id} images={s.items} />;
        case 'awards_table':
          return <AwardsTable key={s.id} input={s} />;
        case 'two_column_text':
          return <TwoColText key={s.id} input={s} />;
        case 'service_slider':
          return <ServiceSlider key={s.id} input={s} />;
        default:
          return null;
      }
    });
    return <div className='slice-zone'>{slice}</div>;
  }
}

PageSliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
};

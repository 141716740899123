import React from 'react';
import WayFadeUp from './transitions/WayFadeUp';
import { PrismicImage } from './common/prismicUtils';
const OneUpImage = ({ primary }) => {
  console.log(primary.landscape_image.fluid, 'primary');
  return (
    <WayFadeUp>
      <div className='pl4 pr4 pl5-l pr5-l mt5 mb5 mt6-l mb6-l flex justify-between items-center mw9 center'>
        <div style={{ aspectRatio: '16/9' }} className='w-100 w-100-l'>
          {primary?.landscape_image && (
            <PrismicImage
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              {...primary.landscape_image.fluid}
            />
          )}
        </div>
      </div>
    </WayFadeUp>
  );
};

export default OneUpImage;

import React from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import JournalFeed from '../components/JournalFeed';
import FadeUp from '../components/transitions/FadeUp';

const JournalPage = ({ data: { allPrismicJournalEntry } }) => {
  const { edges } = allPrismicJournalEntry;
  return (
    <Layout headerColor="dark">
      <SEO title="News" />
      <div className="page-spacer db" />
      <div className="pl4 pr4 pl5-l pr5-l pt5 pb5 pt6-l pb6-l tl tc-l">
        <FadeUp>
          <h1 className="f2 f1-l tiempos mt0 mb0">
            <span className="gold">News </span>/ The latest accolades
          </h1>
        </FadeUp>
      </div>
      <JournalFeed journals={edges} />
    </Layout>
  );
};

export default withPreview(JournalPage);

export const pageQuery = graphql`
  query {
    allPrismicJournalEntry(sort: { fields: [first_publication_date], order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            text {
              html
            }
            page_weight
            image {
              fluid {
                aspectRatio
                ...GatsbyPrismicImageFluid
              }
            }
            link {
              link_type
              url
              target
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';

const deskNav = ({ siteTitle }) => (
  <ul className='list dn flex-l'>
    <li>
      <Link
        to='/projects'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Projects
      </Link>
    </li>
    <li>
      <Link
        to='/about'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        About
      </Link>
    </li>
    <li>
      <Link
        to='/news'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        News
      </Link>
    </li>
    <li>
      <Link
        to='/contact'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Contact
      </Link>
    </li>
    {/* Hide until further notice */}
    {/* <li>
      <Link
        to='/login'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Login
      </Link>
    </li> */}
  </ul>
);

export default deskNav;

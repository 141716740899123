import React from 'react';
import MenuLink from '../nav/MenuLink';
import { useSpring, animated } from 'react-spring';

const navValues = [
  { name: 'Projects', path: '/projects' },
  { name: 'About', path: '/about' },
  { name: 'News', path: '/news' },
  { name: 'Contact', path: '/contact' },
  // { name: 'Login', path: '/login' }, // Hide until further notice
];

const MobileNav = ({ menuState }) => {
  const { opacity } = useSpring({
    from: {
      opacity: '0',
    },
    to: {
      opacity: menuState ? '1' : '0',
    },
  });
  return (
    <>
      <animated.div
        className='w-100 h-100 top-0 left-0 fixed tint-bg flex z-999 items-center'
        style={{
          opacity: opacity,
          pointerEvents: menuState ? 'all' : 'none',
        }}
      >
        <ul className='white list flex-l justify-between items-center w-100 ph4 ph5-l mwnav center tc'>
          {navValues.map((item) => (
            <li>
              <MenuLink linkTo={item.path}>{item.name}</MenuLink>
            </li>
          ))}
        </ul>
      </animated.div>
    </>
  );
};

export default MobileNav;

import React from 'react';

import Layout from '../components/layout';
import PageHeader from '../components/PageHeader';
import '../css/login-page.css';

const LoginPage = () => {
  return (
    <Layout headerColor='dark'>
      <div className='page-spacer db' />
      <PageHeader title={{ text: 'Login' }} subTitle={{ text: 'Portal' }} />
      <div className='pl4 pr4 pl5-l pr5-l login-sub-header '>
        <h2 className='f4'>
          To login to your secure portal, please click throughthe appropriate
          link below.
        </h2>
      </div>
      <div className='login-grid pt3 pt4-l pl4 pr4 pl5-l pr5-l'>
        {/* TODO - add links */}
        <a href='#' className='login-item'>
          <div className='login-item-overlay'></div>
          <span className='login-item-title'>Client Login</span>
        </a>
        <a href='#' className='login-item'>
          <div className='login-item-overlay'></div>
          <span className='login-item-title'>Staff Login</span>
        </a>
      </div>
      <div className='page-spacer db' />
    </Layout>
  );
};

export default LoginPage;

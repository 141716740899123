import React from 'react';
import { Link } from 'gatsby';
import WayFadeUp from './transitions/WayFadeUp';
import { PrismicImage } from './common/prismicUtils';

const HomepageProjectCard = ({ project, isLandscape }) => {
  const { uid, document } = project;
  const { project_color, portal_image, project_name, subheading } =
    document.data;

  return (
    <WayFadeUp>
      <div
        className='center project-card mb6 mb6-l'
        style={{
          aspectRatio: isLandscape ? '3/2' : '3/4',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <Link to={`/projects/${uid}`}>
            <PrismicImage
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              {...portal_image.fluid}
            />
            <div className='w-100 inline-flex-l items-center justify-center pt3 pt0-l proj-overlay dn'>
              <div
                className='absolute top-0 left-0 o-80 h-100 w-100 dn color-overlay'
                style={{
                  backgroundColor: project_color,
                }}
              />
              <h4 className='f3-l grey tiempos-reg z-10 relative'>
                View Project
              </h4>
            </div>
          </Link>

          <span
            style={{
              fontSize: '20px',
              paddingTop: '20px',
              fontWeight: 500,
              display: 'block',
              backgroundColor: '#fcfbf6',
            }}
            className='grey tiempos-reg z-10 relative'
          >
            {project_name.text} / {subheading.text}
          </span>
        </div>
      </div>
    </WayFadeUp>
  );
};

export default HomepageProjectCard;

import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TextLoop from 'react-text-loop';
import PageSliceZone from '../slices/PageSliceZone';
import ProjectSlider from '../components/projects/ProjectSlider';
import FadeUp from '../components/transitions/FadeUp';

const AboutPage = ({ data: { prismicAboutPage, projects } }) => {
  const { data } = prismicAboutPage;
  return (
    <Layout headerColor='dark'>
      <SEO title={data.page_title.text} />
      <div className='page-spacer db' />
      <div className='pl4 pr4 pl5-l pr5-l pt5 pb0 pt6-l pb0-l tl tc-l'>
        <FadeUp>
          <h1 className='f2 f1-l tiempos mt0 mb0'>
            <span className='gold'>We are </span>/ <br className='dn-m dn-l' />
            <TextLoop interval={3000} className='text-loop'>
              <div>Create</div>
              <div>Construct</div>
              <div>Dedicated to Craft</div>
            </TextLoop>
          </h1>
        </FadeUp>
      </div>
      {data.body && <PageSliceZone allSlices={data.body} />}
      <ProjectSlider projects={projects.edges} />
    </Layout>
  );
};

export default withPreview(AboutPage);

export const pageQuery = graphql`
  query {
    prismicAboutPage {
      data {
        page_title {
          html
          text
        }
        body {
          ... on PrismicAboutPageBodyTwoColumnText {
            id
            slice_type
            primary {
              text {
                html
                text
              }
            }
          }
          ... on PrismicAboutPageBodyAwardsTable {
            id
            slice_type
            primary {
              title {
                text
                html
              }
            }
            items {
              award_icon {
                fluid {
                  aspectRatio
                  ...GatsbyPrismicImageFluid
                }
              }
              award_name {
                text
                html
              }
              award_title {
                text
                html
              }
              link {
                url
              }
            }
          }
          ... on PrismicAboutPageBodyCcImageText {
            id
            slice_type
            primary {
              horizontal_text_position
              image {
                fluid {
                  aspectRatio
                  ...GatsbyPrismicImageFluid
                }
              }
              text {
                html
                text
              }
              title {
                html
                text
              }
              vertical_text_postion
            }
          }
          ... on PrismicAboutPageBodyFeaturedImageText {
            id
            slice_type
            items {
              content_description {
                text
              }
              content_title {
                text
              }
            }
            primary {
              horizontal_text_position
              image {
                fluid {
                  aspectRatio
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                html
                text
              }
              vertical_text_postion
            }
          }
          ... on PrismicAboutPageBodyServiceSlider {
            id
            slice_type
            primary {
              title {
                html
                text
              }
            }
            items {
              service_title {
                text
                html
              }
              text {
                text
                html
              }
            }
          }
          ... on PrismicAboutPageBodySingleColumnIntroText {
            id
            slice_type
            primary {
              quote {
                html
                text
              }
              text {
                html
                text
              }
            }
          }
        }
      }
    }
    projects: allPrismicProject(
      sort: { fields: [data___page_weight], order: ASC }
      limit: 6
    ) {
      edges {
        node {
          uid
          data {
            project_name {
              text
            }
            subheading {
              text
            }
            slider_image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            project_color
            page_weight
          }
        }
      }
    }
  }
`;

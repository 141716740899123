import React, { useRef } from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useSpring, animated, useChain, config } from 'react-spring';

const VideoBanner = ({ video, headline }) => {
  const innerRef = useRef();
  const { x, y } = useSpring({
    ref: innerRef,
    from: {
      x: '0',
      y: '20',
    },
    to: {
      x: '1',
      y: '0',
    },
    config: config.molasses,
  });
  const vidRef = useRef();
  const { bg } = useSpring({
    ref: vidRef,
    from: {
      bg: '0',
    },
    to: {
      bg: '1',
    },
    config: config.default,
  });
  useChain([vidRef, innerRef]);
  return (
    <div className='w-100 vh-100 flex items-center bg-black relative overflow-hidden'>
      <animated.div
        style={{
          opacity: bg.interpolate((x) => x),
        }}
      >
        <ReactPlayer
          className='absolute top-0 left-0 reel_teaser o-40'
          url={video.url}
          playing
          muted={true}
          playsinline={true}
          width='100%'
          height='100%'
          loop={true}
        />
      </animated.div>
      <div className='z-99 relative tc center'>
        <animated.div
          style={{
            opacity: x.interpolate((x) => x),
            transform: y.interpolate((y) => `translate3d(0,${y}%, 0)`),
          }}
        >
          <h1 className='tiempos f2 f-subheadline-l lh-copy white tc center mw8'>
            {/* For desktop */}
            <span className='dn db-l'>{headline.text}</span>
            {/* For mobile- three lines */}
            {headline.text.split(' ').map((word, index) => (
              <span key={index} className='db dn-l'>
                {word}
              </span>
            ))}
          </h1>
        </animated.div>
      </div>
      <AnchorLink
        href='#intro'
        className='absolute bottom-0 left-0 z-99 white link f4 tc w-100 scroll-teaser'
        offset='120'
      >
        Scroll Down
      </AnchorLink>
    </div>
  );
};

export default VideoBanner;

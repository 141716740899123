import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntroText from '../components/IntroText';
import ImageText from './ImageText';
import AwardsTable from './AwardsTable';
import SingleIntroText from './SingleColumnIntroText';
import BeltDragger from '../components/BeltDragger';
import OneUpImage from '../components/OneUpImage';
import TwoUpImage from '../components/TwoUpImage';

export default class ProjectSliceZone extends Component {
  render() {
    const { allSlices, projectColor } = this.props;

    const slice = allSlices.map((s) => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'intro_text':
          return (
            <IntroText
              key={s.id}
              quote={s.primary.quote}
              text={s.primary.text}
              color={projectColor}
            />
          );
        case 'single_column_intro_text':
          return (
            <SingleIntroText
              key={s.id}
              quote={s.primary.quote}
              text={s.primary.text}
              color={projectColor}
            />
          );
        case 'image___text':
          return <ImageText key={s.id} input={s} color={projectColor} />;
        case 'carousel_slider':
          return <BeltDragger key={s.id} images={s.items} />;
        case 'awards_table':
          return <AwardsTable key={s.id} input={s} color={projectColor} />;
        case 'one_up_image':
          return <OneUpImage key={s.id} primary={s.primary} />;
        case 'two_up_image':
          return (
            <TwoUpImage
              key={s.id}
              is_reverse_alignment={s.primary?.is_reverse_alignment || false}
              smallImg={s.primary.small_image}
              largeImg={s.primary.large_image}
            />
          );
        default:
          return null;
      }
    });
    return <div className='slice-zone'>{slice}</div>;
  }
}

ProjectSliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
};

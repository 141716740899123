import React from 'react';

import WayFadeUp from '../components/transitions/WayFadeUp';
import '../css/Image-text.css';

import { PrismicImage } from '../components/common/prismicUtils';

const FeaturedImageText = ({ input, color }) => {
  const { image, title, horizontal_text_position, vertical_text_postion } =
    input.primary;

  return (
    <WayFadeUp>
      <div
        id={input.id}
        className={`mt5 mb4 mt6-l mb6-l pl4 pr4 pl5-l pr5-l image-text flex-l mw9 center justify-between tc text-${horizontal_text_position} text-${vertical_text_postion}`}
      >
        {image.fluid && (
          <div
            className={`w-100 w-50-l img ${
              image?.fluid?.aspectRatio < 1 ? 'portrait' : 'landscape'
            }`}
          >
            <PrismicImage {...image.fluid} className='w-100 h-auto' />
          </div>
        )}
        <div className='w-100 w-40-l'>
          {title.text && (
            <h2
              className='f2 lh-copy measure tiempos mt3 mb4 mt0-l'
              style={{ color: `${color}`}}
            >
              {title.text}
            </h2>
          )}

          {input?.items && (
            <div>
              {input.items.map((item, index) => {
                const isLastItem = index === input.items.length - 1;
                return (
                  <div key={item.content_title}>
                    <p className='gold tiempos f3'>{item.content_title.text}</p>
                    <p className='grey f4'>{item.content_description.text}</p>
                    <div
                      className='w-40'
                      style={{
                        borderBottom: '1px solid #A87D65',
                        margin: '0 auto',
                        display: isLastItem ? 'none' : 'block',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </WayFadeUp>
  );
};

export default FeaturedImageText;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import BraveHamburger from './BraveHamburger';
import MobileNav from './mobileNav';
import DeskNav from './deskNav';

const Header = ({ headerColor }) => {
  const [menuOpen, setMenu] = useState(false);
  const headerState = menuOpen ? 'is-open' : 'is-closed';
  return (
    <>
      <header
        className={`pl4 pr4 pl5-l pr5-l pt3 pb3 pt4-l pb4-l flex items-center justify-between w-100 absolute top-0 z-999 header-${headerColor} ${headerState}`}
      >
        <Link to='/' className='logo' />
        <DeskNav />
        <div className='dn-l' onClick={() => setMenu(!menuOpen)}>
          <BraveHamburger menuState={menuOpen} headerColor={headerColor} />
        </div>
      </header>
      <MobileNav menuState={menuOpen} />
    </>
  );
};

export default Header;

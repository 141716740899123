import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/PageHeader';
import ProjectsGrid from '../components/projects/ProjectsGrid';
import ProjectCategoryButtons from '../components/projects/ProjectCategoryButtons';

const ProjectsPage = ({
  data: { prismicProjectsPortal, projects, underConstructionProjects },
}) => {
  const { data } = prismicProjectsPortal;

  const [activeCategory, setActiveCategory] = useState('projects-complete');

  const projectsToDisplay =
    activeCategory === 'projects-complete'
      ? projects.edges
      : underConstructionProjects.edges;

  return (
    <Layout headerColor='dark'>
      <SEO title={data.page_title.text} />
      <div className='page-spacer db' />
      <PageHeader title={data.page_title} subTitle={data.sub_title} />
      <ProjectCategoryButtons
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <ProjectsGrid projects={projectsToDisplay} />
    </Layout>
  );
};

export default withPreview(ProjectsPage);

export const pageQuery = graphql`
  query {
    prismicProjectsPortal {
      data {
        page_title {
          text
        }
        sub_title {
          text
        }
      }
    }
    projects: allPrismicProject(
      sort: { fields: [data___page_weight], order: ASC }
    ) {
      edges {
        node {
          uid
          type
          data {
            project_name {
              text
            }
            subheading {
              text
            }
            portal_image {
              fluid {
                aspectRatio
                ...GatsbyPrismicImageFluid
              }
            }
            project_color
            page_weight
          }
        }
      }
    }
    underConstructionProjects: allPrismicUnderConstruction(
      sort: { fields: [data___page_weight], order: ASC }
    ) {
      edges {
        node {
          uid
          type
          data {
            project_name {
              text
            }
            subheading {
              text
            }
            portal_image {
              fluid {
                aspectRatio
                ...GatsbyPrismicImageFluid
              }
            }
            project_color
            page_weight
          }
        }
      }
    }
  }
`;
